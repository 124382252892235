import React, { useEffect, useState } from "react";
import "../../../scss/pages/cards.scss";
import { NavLink } from "react-router-dom";
import {
  dateFormat,
  getLongMonth,
  getPackagesForCurrentAndUpcomingMonths,
} from "../../../function";
import { GET } from "../../../api/method";

const Index = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await GET("/getAllPackages", {});
        const pkgs = getPackagesForCurrentAndUpcomingMonths(response);

        console.log({ pkgs });
        setPackages(pkgs);
      } catch (error) {
        console.error("Error fetching packages", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div>
      <div className="heading">
        <h1>Travel this month</h1>
        <p>
          Below you will find an overview of all Umrah and Hajj packages this
          month. Click on one of the trips for detailed information about this
          package.
        </p>
      </div>
      <div className="cards">
        {packages.map((elem) => (
          <div className="innercards">
            <h2>
              Umrah in {getLongMonth(elem.departureTime)}{" "}
              {new Date(elem.departureTime).getFullYear()}
            </h2>
            <div className="checkin">
              <NavLink to={`/details/${elem.id}`}>
                <h3>View packages </h3>
              </NavLink>
            </div>
          </div>
        ))}
        {/* <div className="innercards">
          <h2>Umrah in March 2024</h2>
          <div className="checkin">
            <NavLink to="/umrah">
              <h3>View packages </h3>
            </NavLink>{" "}
          </div>
        </div>{" "}
        <div className="innercards">
          <h2>Umrah in March 2024</h2>
          <div className="checkin">
            <NavLink to="/umrah">
              <h3>View packages </h3>
            </NavLink>{" "}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Index;

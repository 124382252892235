import React from "react";
import Hajj from "../../../images/hajj.jpg";
import "../../../scss/pages/hajj.scss";
const index = () => {
  return (
    <>
      <div className="picture">
        <img src={Hajj}></img>
      </div>
      <div class="container">
        <h1>Hajj 2024 Pilgrimage Registration</h1>
        <p>Register your details as a pilgrim for Hajj 2024 / 1445 on Nusuk.</p>
        <p>
          In the context of the preparations for the Hajj 2024 / 1445, we would
          like to guide you in registering on the website of the Ministry of
          Hajj (Nusuk). To do this, follow the steps below carefully:
        </p>
        <h2>First preparations:</h2>
        <ol>
          <li>Provide a passport photo (200 x 200 | max 18KB)</li>
          <li>Copy of your passport</li>
          <li>Valid email address</li>
          <li>Phone number</li>
        </ol>
        <h2>Steps for registration:</h2>
        <ol>
          <li>
            Visit the Ministry of Hajj (Nusuk) website via the following link:{" "}
            <a href="https://hajj.nusuk.sa/">https://hajj.nusuk.sa/</a>
          </li>
          <li>First create an account for yourself.</li>
          <li>
            Register your details as a pilgrim by following the instructions on
            the site.
          </li>
          <li>Upload the required documents and submit your application.</li>
        </ol>
      </div>
    </>
  );
};

export default index;

const data = [
  {
    title: "Tour in Germany 2024",
    date: "March 2024",
    link: "/umrah",
    image: "/germany.JPG",
  },
  {
    title: "Tour in paris 2024",
    date: "April 2024",
    link: "/umrah",
    image: "/paris.jpg",
  },
  {
    title: "Tour in london 2024",
    date: "May 2024",
    link: "/umrah",
    image: "/london.jpeg",
  },
  // Add more trip data as needed
];

export default data;

import React, { useState, useEffect } from "react";
import { GET } from "../../../api/method";
import "../../../scss/pages/umrah.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faHotel,
  faCar,
  faPassport,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import Kaaba from "../../../images/card1.png";
import { NavLink } from "react-router-dom";
import { dateFormat, getLongMonth, getUpcomingMonths } from "../../../function";

const Index = ({ applyFilter }) => {
  const [filters, setFilters] = useState({
    year: {},
    month: {},
  });
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [upcomingMonths, setUpcomingMonths] = useState([]);

  useEffect(() => {
    let result = [...packages];
    const yearFilterValues = Object.values(filters.year);
    const monthFilterValues = Object.values(filters.month);

    if (yearFilterValues.includes(true)) {
      result = result.filter((pkg) => {
        const val = pkg?.departureTime;
        const yearVal = val ? new Date(val).getFullYear() : null;

        return filters?.year[yearVal];
      });
    }

    if (monthFilterValues.includes(true)) {
      result = result.filter((pkg) => {
        const val = pkg.departureTime;
        const monthVal = val ? getLongMonth(val) : null;

        return filters?.month[monthVal];
      });
    }

    setFilteredPackages(result);
  }, [packages, filters]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await GET("/getAllPackages", {});
        console.log("Fetched Packages:", response); // Debug: Check fetched data
        setPackages(response);
        setFilteredPackages(response);
      } catch (error) {
        console.error("Error fetching packages", error);
      }
    };

    fetchPackages();
    const upcomingMonths = getUpcomingMonths(4);
    setUpcomingMonths(upcomingMonths);
  }, []);

  const handleChange = (event, filterType) => {
    const { value, checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: {
        ...filters[filterType],
        [value]: checked,
      },
    });
  };

  // const handleChange = (event) => {
  //   const { name, type, value, checked } = event.target;

  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));
  // };

  const handleApply = () => {
    applyFilter(filters);
  };

  return (
    <div className="maincontainer">
      <div className="main2">
        <div className="heading">
          <h1>Umrah 2024</h1>
        </div>
        <div className="text1">
          View our wide range of various Umrah 2024 packages from different
          periods. Easily filter by year and/or month to find the package that
          best suits your wishes and requirements.
        </div>
        <div className="filter-container">
          <h2>Year</h2>
          <div className="checkboxes">
            <label>
              <input
                type="checkbox"
                name="year"
                value={2024}
                checked={filters?.year["2024"]}
                onChange={(e) => handleChange(e, "year")}
              />
              2024
            </label>
          </div>
          <div className="checkboxes">
            <label>
              <input
                type="checkbox"
                name="year"
                value={2025}
                checked={filters?.year["2025"]}
                onChange={(e) => handleChange(e, "year")}
              />
              2025
            </label>
          </div>
          <h2>Month</h2>
          <div className="checkboxes">
            {upcomingMonths.map((elem) => (
              <label>
                <input
                  type="checkbox"
                  name="month"
                  value={elem}
                  checked={filters?.month[elem]}
                  onChange={(e) => handleChange(e, "month")}
                />
                {elem}
              </label>
            ))}
            {/* <label>
              <input
                type="checkbox"
                name="option2"
                checked={filters.option2}
                onChange={handleChange}
              />
              May
            </label>
            <label>
              <input
                type="checkbox"
                name="option5"
                checked={filters.option5}
                onChange={handleChange}
              />
              June
            </label> */}
          </div>
          {/* <h2>Destination</h2>
          <div className="checkboxes">
            <label>
              <input
                type="checkbox"
                name="option3"
                checked={filters.option3}
                onChange={handleChange}
              />
              MADINA
            </label>
            <label>
              <input
                type="checkbox"
                name="option4"
                checked={filters.option4}
                onChange={handleChange}
              />
              MAKKAH
            </label>
          </div> */}
        </div>
        {/* <div className="price-slider">
          <label htmlFor="price">Price:</label>
          <input
            type="range"
            id="price"
            name="price"
            min="0"
            max="1000"
            value={filters.price}
            onChange={handleChange}
          />
          <span>${filters.price}</span>
        </div> */}
      </div>
      <div className="main">
        {filteredPackages?.length > 0 ? (
          filteredPackages?.map((pkg) => (
            <div className="card" key={pkg.id}>
              <div className="picture">
                <img src={Kaaba} alt="Kaaba" />
              </div>
              <div className="text">
                <div className="maintext">
                  <div className="first">
                    <h2>{pkg.packageName}</h2>
                  </div>
                  <div className="Second">
                    <p>
                      {pkg.available ? "Available" : "Available"}{" "}
                      <span
                        className={pkg.available ? "green-spot" : "red-spot"}
                      ></span>
                    </p>
                  </div>
                </div>
                <p>
                  Umrah holiday from: {dateFormat(pkg.departureTime)} to{" "}
                  {dateFormat(pkg.returnDate)}
                </p>
                <h5>
                  <span>Start destination:</span> {pkg.from}
                </h5>
                <h5>
                  <span>End destination:</span> {pkg.secondDestination}
                </h5>
                <div className="icons">
                  <FontAwesomeIcon icon={faPassport} /> Visa
                  <FontAwesomeIcon icon={faPlane} /> Airline ticket
                  <FontAwesomeIcon icon={faHotel} /> Hotels
                  <FontAwesomeIcon icon={faMapMarkedAlt} /> Field trips
                  <FontAwesomeIcon icon={faCar} /> Transportation
                </div>
                <div className="btn">
                  <NavLink to={`/details/${pkg.id}`}>
                    <button>More information</button>
                  </NavLink>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No packages available</p>
        )}
      </div>
    </div>
  );
};

export default Index;

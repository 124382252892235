import React from "react";
import Main from "./int/index";
const index = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default index;

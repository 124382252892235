import React, { useState } from "react";
// import Logo from "../../images/umrah-logo.jpeg";
import Logo from "../../images/logo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header__left">
        <NavLink to="/">
          <div className="logo">
            <img src={Logo} alt="Umrah Logo" />
          </div>
        </NavLink>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61559438932782&mibextid=LQQJ4d"
            target="blank"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              style={{ color: "#0866FF" }}
              className="icon"
            />
          </a>
          <a
            href="https://www.instagram.com/travel.unite/?igsh=MXVrbm9oMmJtbTNzcg%3D%3D"
            target="blank"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              style={{
                color: "#BF2792",
              }}
              className="icon"
            />
          </a>
        </div>
      </div>

      <FontAwesomeIcon
        icon={faBars}
        className="menu-icon"
        onClick={toggleMenu}
      />
      <div className="header__right">
        <ul className={`categories ${isOpen ? "open" : ""}`}>
          <NavLink to="/checklist">
            <li>Umrah checklist</li>
          </NavLink>
          <NavLink to="/aboutus">
            <li>About Us</li>
          </NavLink>
          {/* <NavLink to="/contactus">
            <li>Contact us</li>
          </NavLink> */}
          <NavLink to="/umrah">
            <li>
              <a href="#" className="button">
                Umrah
              </a>
            </li>
          </NavLink>
          <NavLink to="/hajj">
            <li>
              <a
                href="#"
                className="button"
                style={{ backgroundColor: "var(--primary)" }}
              >
                Hajj
              </a>
            </li>
          </NavLink>
        </ul>
      </div>

      <div className={`menu-slide ${isOpen ? "open" : ""}`}>
        <button onClick={toggleMenu} className="close-btn">
          X
        </button>
        <ul className="sidebar-menue-links">
          <a href="/checklist">
            <li>Umrah checklist</li>
          </a>
          <a href="/aboutus">
            <li>About us</li>
          </a>{" "}
          {/* <a href="/contactus">
            <li>Contact us</li>
          </a>{" "} */}
          <a href="/umrah">
            <li>Umrah</li>
          </a>{" "}
          <a href="/hajj">
            <li>Hajj</li>
          </a>
        </ul>
      </div>
    </header>
  );
};

export default Header;

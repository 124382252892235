import axios from "axios";

export const URL = "https://api.flowhrm.site";

// const getToken = () => {
//   const token = localStorage.getItem("token");
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   return config
// }

export const POST = (endpoint, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(URL + endpoint, payload())
      .then((res) => resolve(res.data))
      .catch((err) =>
        reject({
          message: err?.response?.data?.message || "Something went wrong",
        })
      );
  });
};

export const GET = (endpoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(URL + endpoint)
      .then((res) => resolve(res.data))
      .catch(() =>
        reject({
          message: "Something went wrong",
        })
      );
  });
};

import React from "react";
import Main from "./doc/index";
const index = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default index;

import React from "react";
import Umrah from "../../../images/umrah-p.png";
import Fo from "../../../images/fo.png";
import "../../../scss/pages/about.scss";

const index = () => {
  return (
    <div>
      <div className="aboutmain">
        <div className="aboutuss">
          <h2>About Travel Unite</h2>
          <p>
            TravelUnite is not just a travel agency; we are your guide during
            Umrah and Hajj. TravelUnite was created to offer believers an
            unforgettable and enriching pilgrimage, striving for perfection in
            every detail of your journey.
          </p>
        </div>
        <div className="aboutusspicture">
          <img src={Umrah}></img>
        </div>
      </div>
      <div className="aboutmain">
        <div className="aboutusspicture">
          <img src={Fo}></img>
        </div>
        <div className="aboutuss">
          <h2>About Travel Unite</h2>
          <p>
            TravelUnite is not just a travel agency; we are your guide during
            Umrah and Hajj. TravelUnite was created to offer believers an
            unforgettable and enriching pilgrimage, striving for perfection in
            every detail of your journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;

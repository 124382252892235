import React from "react";
// import Slider from "react-slick";
import "../../../scss/pages/slidercard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faHotel,
  faCar,
  faPassport,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import Azer from "../../../images/Azerbaijan.jpg";
// import Dubai2 from "../../../images/dubai.jpg";
import Dubai from "../../../images/dubai.PNG";
import Thai from "../../../images/bangkok.jpeg";
import Turkey from "../../../images/turkey.jpg";

import { NavLink } from "react-router-dom";
const Index = ({ available }) => {
  const availabilitySpot = available ? "green-spot" : "red-spot";
  const availabilityText = available ? "Available" : "Available";

  return (
    <>
      <div className="wrapper" style={{ width: "90%", margin: "auto" }}>
        <div>
          <div className="main">
            <div className="card">
              <div className="picture">
                <img src={Azer} alt="Kaaba" />
              </div>
              <div className="text">
                <div className="maintext">
                  <div className="first">
                    <h2>Azerbahijan package</h2>
                  </div>
                  {/* <div className="Second">
                    <p>
                      {availabilityText}{" "}
                      <span className={availabilitySpot}></span>
                    </p>
                  </div> */}
                </div>

                <p>Azerbahijan May holiday from: 25-04-2024 to 04-05-2024</p>
                <h5>
                  <span>Start destination:</span>Baku
                </h5>
                <h5>
                  <span>End destination:</span>Ganja
                </h5>
                <div className="icons">
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faPassport}
                  />{" "}
                  Visa
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faPlane}
                  />{" "}
                  Airline ticket
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faHotel}
                  />{" "}
                  Hotels
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faMapMarkedAlt}
                  />{" "}
                  Field trips
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faCar}
                  />{" "}
                  Transportation
                </div>
                <div className="btn">
                  <NavLink to="/details">
                    <button>More information</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div>
          <div className="main">
            <div className="card">
              <div className="picture">
                <img src={Dubai} alt="Kaaba" />
              </div>
              <div className="text">
                <div className="maintext">
                  <div className="first">
                    <h2>U.A.E package</h2>
                  </div>
                  {/* <div className="Second">
                    <p>
                      {availabilityText}{" "}
                      <span className={availabilitySpot}></span>
                    </p>
                  </div> */}
                </div>

                <p>U.A.E May holiday from: 25-04-2024 to 04-05-2024</p>
                <h5>
                  <span>Start destination:</span>Dubai
                </h5>
                <h5>
                  <span>End destination:</span>Abu Dhabi
                </h5>
                <div className="icons">
                  <FontAwesomeIcon icon={faPassport} /> Visa
                  <FontAwesomeIcon icon={faPlane} /> Airline ticket
                  <FontAwesomeIcon icon={faHotel} /> Hotels
                  <FontAwesomeIcon icon={faMapMarkedAlt} /> Field trips
                  <FontAwesomeIcon icon={faCar} /> Transportation
                </div>
                <div className="btn">
                  <NavLink to="/details">
                    <button>More information</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div>
          <div className="main">
            <div className="card">
              <div className="picture">
                <img src={Thai} alt="Kaaba" />
              </div>
              <div className="text">
                <div className="maintext">
                  <div className="first">
                    <h2>Thailand package</h2>
                  </div>
                  {/* <div className="Second">
                    <p>
                      {availabilityText}{" "}
                      <span className={availabilitySpot}></span>
                    </p>
                  </div> */}
                </div>

                <p>Thailand holiday from: 25-04-2024 to 04-05-2024</p>
                <h5>
                  <span>Start destination:</span>Bangkok
                </h5>
                <h5>
                  <span>End destination:</span>Phuket
                </h5>
                <div className="icons">
                  <FontAwesomeIcon icon={faPassport} /> Visa
                  <FontAwesomeIcon icon={faPlane} /> Airline ticket
                  <FontAwesomeIcon icon={faHotel} /> Hotels
                  <FontAwesomeIcon icon={faMapMarkedAlt} /> Field trips
                  <FontAwesomeIcon icon={faCar} /> Transportation
                </div>
                <div className="btn">
                  <NavLink to="/details">
                    <button>More information</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div>
          <div className="main">
            <div className="card">
              <div className="picture">
                <img src={Turkey} alt="Kaaba" />
              </div>
              <div className="text">
                <div className="maintext">
                  <div className="first">
                    <h2>Turkey package</h2>
                  </div>
                  {/* <div className="Second">
                    <p>
                      {availabilityText}{" "}
                      <span className={availabilitySpot}></span>
                    </p>
                  </div> */}
                </div>

                <p>Turkey holiday from: 25-04-2024 to 04-05-2024</p>
                <h5>
                  <span>Start destination:</span>Istanbul
                </h5>
                <h5>
                  <span>End destination:</span>Ankara
                </h5>
                <div className="icons">
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faPassport}
                  />{" "}
                  Visa
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faPlane}
                  />{" "}
                  Airline ticket
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faHotel}
                  />{" "}
                  Hotels
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faMapMarkedAlt}
                  />{" "}
                  Field trips
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faCar}
                  />{" "}
                  Transportation
                </div>
                <div className="btn">
                  <NavLink to="/details">
                    <button>More information</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Index;

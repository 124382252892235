import * as React from "react";
import { useEffect, useState } from "react";
import { GET, URL } from "../../../api/method";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../../scss/pages/pckg.scss";
import Iframe from "react-iframe";
import Slider from "react-slick";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneDeparture,
  faPlaneArrival,
  faClock,
  faPlane,
  faHotel,
  faCar,
  faPassport,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import Anjum from "../../../images/anjum3.png";
import Anjum1 from "../../../images/anjum2.png";
import Anjum2 from "../../../images/anjum5.png";
import Anjum3 from "../../../images/anjum6.png";
import { dateFormat } from "../../../function";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [packages, setPackages] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [packages, setPackages] = useState([]);
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await GET(`/getPackage/${id}`);
        console.log("Fetched Packages:", response); // Debug: Check fetched data
        setPackages(response);
      } catch (error) {
        console.error("Error fetching packages", error);
      }
    };

    fetchPackages();
  }, [id]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (packages.length === 0) {
    return <div>No packages available</div>;
  }

  const packageData = packages; // Assuming you only have one package to display
  console.log(packages, "-------");

  const images = [];

  packages.Hotels.map((hotel) => {
    if (JSON.parse(hotel.hotelImage)?.length > 0) {
      const imgs = JSON.parse(hotel.hotelImage);
      imgs.map((elem) => images.push(URL + "/image/" + elem));
    }
  });

  console.log({ images });

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs Navigation"
        >
          <Tab style={{ width: "25%" }} label="Information" {...a11yProps(0)} />
          <Tab
            style={{ width: "25%" }}
            label="Flights details"
            {...a11yProps(1)}
          />
          <Tab style={{ width: "25%" }} label="Hotels" {...a11yProps(2)} />
          <Tab style={{ width: "25%" }} label="Prices" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="information">
          <div className="firstcontainer">
            <h3>
              {packageData.packageName} <br /> Last spot
            </h3>
            {/* <p className="btns">From {packageData?.price} pp</p> */}
            <hr />
          </div>
          <div className="secondcontainer">
            <FontAwesomeIcon
              style={{ color: "var(--primary)", marginRight: "5px" }}
              icon={faPlaneDeparture}
            />
            Departure date: {dateFormat(packageData?.departureTime)}
            <br />
            <FontAwesomeIcon
              style={{ color: "var(--primary)", marginRight: "5px" }}
              icon={faPlaneArrival}
            />
            Return date: {dateFormat(packageData?.returnDate)}
            <br />
            <FontAwesomeIcon
              style={{ color: "var(--primary)", marginRight: "8px" }}
              icon={faClock}
            />
            Number of days: {packageData?.days}
            <br />
            <FontAwesomeIcon
              style={{ color: "var(--primary)", marginRight: "6px" }}
              icon={faClock}
            />
            Number of nights: {packageData?.nights}
            <br />
            <hr />
          </div>

          {/* <div className="thirdcontainer">
            <h5>Included</h5>
            <div className="iconss">
              <FontAwesomeIcon
                style={{ color: "var(--primary)" }}
                icon={faPassport}
              />{" "}
              Visa
              <FontAwesomeIcon
                style={{ color: "var(--primary)" }}
                icon={faPlane}
              />{" "}
              Airline ticket
              <FontAwesomeIcon
                style={{ color: "var(--primary)" }}
                icon={faHotel}
              />{" "}
              Hotels
              <FontAwesomeIcon
                style={{ color: "var(--primary)" }}
                icon={faMapMarkedAlt}
              />{" "}
              Field trips
              <FontAwesomeIcon
                style={{ color: "var(--primary)" }}
                icon={faCar}
              />{" "}
              Transportation
            </div>
            <hr />
          </div> */}
          {/* <div className="fourthcontainer">
            <h3>Bring your own:</h3>
            <p>
              Please make sure you only bring your passport, along with any
              money required for personal expenses.
            </p>
          </div> */}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="travel-information">
          <h2>Travel information</h2>
          <p>
            Below you will find all relevant information regarding the complete
            travel package.
          </p>
          {/* <p>
            <strong>Please note:</strong> Babies up to 24 months do not have a
            seat!
            <hr />
          </p> */}
          <div className="detailss">
            <h3>Flying from:</h3>
            <p>{packageData?.from}</p>
            <hr />

            <h3>Airline:</h3>
            <p>{packageData?.airLine}</p>
            <hr />

            <h3>Baggage allowance:</h3>
            <p>{packageData?.bagAllowance}</p>
            <hr />

            <h3>Stopover in:</h3>
            <p>{packageData?.stopOver}</p>
            <hr />

            <h3>Departure time:</h3>
            <p>{dateFormat(packageData?.departureTime)}</p>
            <hr />

            <h3>Arrival time:</h3>
            <p>{dateFormat(packageData?.arivalTime)}</p>
            <hr />

            <h3>Arrival destination:</h3>
            <p>{packageData?.to}</p>
            <hr />

            <h3>Second destination:</h3>
            <p>{packageData?.secondDestination}</p>
            <hr />

            <h3>Route:</h3>
            <p>{packageData?.route}</p>
            <hr />
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div>
          <div className="textslider">
            <h4>Hotels</h4>
            <p>
              Below you will find all relevant information regarding your stay
              in the hotels.
            </p>
            <hr />
            <h3>{packageData?.hotelName} </h3>
          </div>
        </div>
        <div className="slider-container">
          <Slider {...settings}>
            {images.map((img) => (
              <div className="pictures">
                <img src={img} alt="Anjum Hotel" />
              </div>
            ))}
            {/* <img src={Anjum} alt="Anjum Hotel" />
            </div>
            <div className="pictures">
              <img src={Anjum1} alt="Anjum Hotel" />
            </div>
            <div className="pictures">
              <img src={Anjum2} alt="Anjum Hotel" />
            </div>
            <div className="pictures">
              <img src={Anjum3} alt="Anjum Hotel" />
            </div> */}
          </Slider>
          {/* <div className="map-container">
            <Iframe
              url="https://www.google.com/maps/d/embed?mid=1df5SO_ukf4YehJ8Bc1YtgfpGVno&hl=en&ehbc=2E312F"
              width="640"
              height="480"
              id="myId"
              className="map-iframe"
              display="initial"
              position="relative"
            />
          </div> */}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div className="prices-container">
          {packages?.Prices?.map((category, index) => (
            <div className="price-category" key={index}>
              <h2>{category.persons}</h2>
              <p>Amount per person {category.amountPerPerson}</p>
            </div>
          ))}
        </div>
        {/* <div class="hotel-info">
          <h2>Hotel Information</h2>
          <p>
            The hotel in Makkah has standard double rooms. In a 3 and 4 person
            room, an extra bed is placed in the room. This bed is smaller in
            size than the other 2 beds in the room.
          </p>
          <p>
            For travelers traveling alone, we are dependent on other fellow
            travelers who are also traveling alone for the room layout. Please
            note that the desired room can be changed if no roommate is found.
          </p>
          <h3>Extra Services:</h3>
          <ul>
            <li>
              Discount for child with bed (0-12 years): €100 (please indicate
              this when booking)
            </li>
            <li>Child without bed: €1,500</li>
            <li>Baby: €400</li>
          </ul>
        </div> */}
      </CustomTabPanel>
    </Box>
  );
}

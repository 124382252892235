import Banner from "./banner/index";
import Card from "./cards/index";
import Slider from "./slidercard/index";
import Tour from "./tours/index";
const Index = () => {
  return (
    <>
      <Banner />
      <Card />
      <Tour />
      <Slider />
    </>
  );
};

export default Index;

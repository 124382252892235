import React from "react";
import Slider from "react-slick"; // Importing Slider from react-slick
import "../../../scss/pages/home.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../../images/banner.jpg";
import Banner2 from "../../../images/banner2.jpeg"; // Updated to match the correct filename
import { NavLink } from "react-router-dom";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  return (
    <div>
      <Slider {...settings}>
        <div className="banner">
          <img src={Banner} alt="Banner2" />
          <div className="details">
            <h1>Our Packages</h1>
            <ul className="homebtns">
              <li>
                <NavLink to="/umrah" className="button">
                  Umrah
                </NavLink>
              </li>
              <li>
                <NavLink to="/tour">
                  <a
                    className="button"
                    style={{ backgroundColor: "var(--primary)" }}
                  >
                    International tours
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hajj"
                  className="button"
                  // style={{ backgroundColor: "var(--primary)" }}
                >
                  Hajj
                </NavLink>
              </li>
            </ul>
            <h1>Your journey, our dedication</h1>
            <h1>العتيق البيت الى الطريق بداية</h1>
          </div>
        </div>
        <div className="banner2">
          <img style={{ height: "40%" }} src={Banner2} alt="Banner2" />
          <div className="details">
            <h1>Our Packages</h1>
            <ul className="homebtns">
              <li>
                <a href="/umrah" className="button">
                  Umrah
                </a>
              </li>
              <li>
                <NavLink to="/tour">
                  <a
                    className="button"
                    style={{ backgroundColor: "var(--primary)" }}
                  >
                    International tours
                  </a>
                </NavLink>
              </li>
              <li>
                <a href="/hajj" className="button">
                  Hajj
                </a>
              </li>
            </ul>
            <h1>Your journey, our dedication</h1>
            <h1>العتيق البيت الى الطريق بداية</h1>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Home;

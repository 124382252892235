import React from "react";
import "../../../scss/pages/list.scss";
const index = () => {
  return (
    <div>
      <div className="listmain">
        <div className="list">
          <h1>Umrah Checklist 2024</h1>
          <p>
            In this Umrah checklist you can find everything you need to perform
            your Umrah or Hajj without any worries.
          </p>
        </div>
        <div class="container">
          <h1>Umrah Checklist for a Worry-Free Pilgrimage</h1>
          <p>
            Prepare for a spiritual journey of devotion and connection by using
            our comprehensive Umrah checklist. This checklist has been put
            together to ensure that every step of your pilgrimage runs smoothly
            and without complications, so that you can fully concentrate on the
            essence of your Umrah experience.
          </p>

          <h2>Documents</h2>
          <ul>
            <li>Passport and visa. (Make a copy of this in case of loss.)</li>
            <li>Medication list. (Put this in your phone too)</li>
            <li>Debit card (Don't forget to convert to worldwide)</li>
          </ul>

          <h2>For men</h2>
          <ul>
            <li>
              Ihraam: You can buy this in advance of the trip, but the first
              destination is going to be Madinah. Here you can also buy the
              Ihraam. Take a pin with you if you can't get it tied properly.
              This is an important part of the Umrah checklist!
            </li>
            <li>Kamis or Jelleba: at least 3</li>
          </ul>

          <h2>For women</h2>
          <ul>
            <li>Abayaat: minimum 3</li>
            <li>Large headscarves: at least 2</li>
          </ul>

          <h2>Toiletries</h2>
          <ul>
            <li>Shampoo (free of perfume)</li>
            <li>Toothbrush, toothpaste, siwaq stick and/or dental floss</li>
            <li>Deodorant (free of perfume)</li>
            <li>Nail clippers</li>
            <li>Vaseline</li>
            <li>Shaving supplies. (Razor/shaving cream/hair clippers)</li>
            <li>Sunscreen</li>
            <li>Blister plaster</li>
            <li>Basic first aid kit</li>
            <li>Sudocrem</li>
            <li>
              Contact lenses and lens solution and possibly extra glasses.
            </li>
            <li>Washcloths & towels</li>
          </ul>

          <h2>Medicines</h2>
          <ul>
            <li>Don't forget your own medication!</li>
            <li>Paracetamols with and without caffeine. (Reduces fever)</li>
            <li>Ear drops</li>
            <li>Trachitol (works against sore throat)</li>
            <li>ORS effervescent tablet (works against dehydration)</li>
            <li>
              Vitamin C (Many people catch a cold from, for example, the carpets
              and curtains in hotel rooms. A good dose of vitamin C can
              stimulate healing.)
            </li>
          </ul>

          <h2>General</h2>
          <ul>
            <li>Prayer rug: you can also buy there.</li>
            <li>
              Shoes: It is very important that they are good, comfortable,
              sturdy shoes that you can walk in for a long time. Advice: no new
              shoes to avoid blisters.
            </li>
            <li>
              Slippers: For wudu. Advice: no new slippers to avoid blisters.
            </li>
            <li>
              Cotton underwear: Doesn't necessarily have to be cotton, but it is
              nicer because of the heat and sweating.
            </li>
            <li>
              Cotton shirts: Not necessarily cotton, but it is nicer because of
              the heat and sweating.
            </li>
            <li>Sleepwear</li>
            <li>
              Snacks: e.g. gingerbread nuts, dates, etc. Are also for sale in
              Medina and Mecca.
            </li>
          </ul>

          <h2>Additional</h2>
          <ul>
            <li>
              Neck bag. (You can easily take things with you here as there are
              no pockets.)
            </li>
            <li>Vaccination has been abolished.</li>
            <li>Water bottle.</li>
            <li>Notebook and pen.</li>
            <li>
              Sunglasses/cap/hat. (The sun there is very bright and the white
              tiles of Masjid Al Haram in combination with the sun shining on
              them have a blinding effect. So a cap is not enough, you will need
              a cap and sunglasses or an umbrella and sunglasses. PLEASE NOTE
              OP: In a state of Ihraam you are not allowed to wear a cap as a
              man!)
            </li>
            <li>Quran, dua booklet</li>
            <li>
              Thin bag (you will receive it from us. You can put your shoes in
              this when you enter the mosque.)
            </li>
            <li>
              World plug: (Some hotels there do not have a normal socket like we
              have in the Netherlands. That is why it is best to buy a socket
              converter.)
            </li>
            <li>Neck pillow: (For the long journeys you have to make.)</li>
            <li>Cash.</li>
            <li>Credit card.</li>
            <li>Copy of insurance policy and pass.</li>
            <li>Chargers/power bank.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default index;

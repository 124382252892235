import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { GET } from "../../../api/method";
import "../../../scss/pages/slidercard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faHotel,
  faCar,
  faPassport,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import Kaaba from "../../../images/card1.png";
import { NavLink } from "react-router-dom";
import { dateFormat } from "../../../function";

const Index = ({ available }) => {
  const [packages, setPackages] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await GET("/getAllPackages", {});
        console.log("Fetched Packages:", response); // Debug: Check fetched data
        setPackages(response);
      } catch (error) {
        console.error("Error fetching packages", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div className="wrapper" style={{ width: "90%", margin: "auto" }}>
      <Slider {...settings}>
        {packages.length > 0 ? (
          packages.map((pkg) => (
            <div key={pkg.id}>
              <div className="main">
                <div className="card">
                  <div className="picture">
                    <img src={Kaaba} alt="Kaaba" />
                  </div>
                  <div className="text">
                    <div className="maintext">
                      <div className="first">
                        <h2>{pkg.packageName}</h2>
                      </div>
                      <div className="Second">
                        <p>
                          {pkg.available ? "Available" : "Available"}{" "}
                          <span
                            className={
                              pkg.available ? "green-spot" : "red-spot"
                            }
                          ></span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Umrah holiday from: {dateFormat(pkg.departureTime)}{" "}
                      <strong>to</strong> {dateFormat(pkg.returnDate)}
                    </p>
                    <h5>
                      <span>Start destination:</span> {pkg.from}
                    </h5>
                    <h5>
                      <span>End destination:</span> {pkg.secondDestination}
                    </h5>
                    <div className="icons">
                      <FontAwesomeIcon icon={faPassport} /> Visa
                      <FontAwesomeIcon icon={faPlane} /> Airline ticket
                      <FontAwesomeIcon icon={faHotel} /> Hotels
                      <FontAwesomeIcon icon={faMapMarkedAlt} /> Field trips
                      <FontAwesomeIcon icon={faCar} /> Transportation
                    </div>
                    <div className="btn">
                      <NavLink to={`/details/${pkg.id}`}>
                        <button>More information</button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No packages available</p>
        )}
      </Slider>
    </div>
  );
};

export default Index;

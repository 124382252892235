import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import AppRouter from "./navigation";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./scss/index.scss";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />

        <AppRouter />

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

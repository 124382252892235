import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Umrah from "../pages/umrahdetail/index";
import Hajj from "../pages/hajj/index";
import List from "../pages/umrahchecklist/index";
import Pckg from "../pages/detail/index";
import Contact from "../pages/contact/index";
import About from "../pages/about/index";
import Tour from "../pages/tours/index";
import { dateFormat } from "../function";

const publicRoutes = [
  {
    path: "/",
    label: "Home",
    component: <Home />,
    private: true,
  },
  {
    path: "/umrah",
    label: "Umrah",
    component: <Umrah />,
    private: true,
  },
  {
    path: "/hajj",
    label: "Hajj",
    component: <Hajj />,
    private: true,
  },
  {
    path: "/checklist",
    label: "List",
    component: <List />,
    private: true,
  },
  {
    path: `/details/:id`,
    label: "Pckg",
    component: <Pckg />,
    private: true,
  },
  {
    path: "/contactus",
    label: "Contact",
    component: <Contact />,
    private: true,
  },
  {
    path: "/aboutus",
    label: "About",
    component: <About />,
    private: true,
  },
  {
    path: "/tour",
    label: "tour",
    component: <Tour />,
    private: true,
  },
];

const AppRouter = () => {
  // const { id } = useAuth();

  const publicPageRoutes = publicRoutes.map(({ label, path, component }) => {
    return <Route key={label} path={`/${path}`} element={component} />;
  });

  return (
    <Routes>
      <Route path="/">
        {/* public routes */}
        {publicPageRoutes}

        {/* protected routes */}
        {/* <Route element={<RequireAuth />}>{protectedPageRoutes}</Route> */}

        {/* catch all */}
        {/* <Route path="*" element={<Navigate to={id ? '/' : '/login'} />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRouter;

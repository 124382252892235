import React from "react";
import Logo from "../../images/logo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <h3>
          <img style={{ width: "100px" }} src={Logo}></img>
        </h3>
        <p className="footer-links">
          <a href="/checklist" className="link-1">
            Umrah Checklist
          </a>
          <a href="/umrah">Umrah</a>
          <a href="/hajj">Hajj</a>
          <a href="/aboutus">About-Us</a>
          {/* <a href="contactus">Contact</a> */}
        </p>
        <p className="footer-company-name">Travel Unite © 2024</p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-map-marker"></i>
          <p>
            <span>444 S. Cedros Ave</span> msterdam, Netherlands
          </p>
        </div>
        <div>
          <i className="fa fa-phone"></i>
          <p>+31 6 23901956</p>
        </div>
        <div>
          <i className="fa fa-envelope"></i>
          <p>info@travel-unite.com</p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>About the company</span>
          Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce
          euismod convallis velit, eu auctor lacus vehicula sit amet.
        </p>
        <div className="footer-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61559438932782&mibextid=LQQJ4d"
            target="blank"
          >
            <i className="fa fa-linkedin">
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ color: "#0866FF" }}
                className="icon"
              />
            </i>
          </a>
          <a
            href="https://www.instagram.com/travel.unite/?igsh=MXVrbm9oMmJtbTNzcg%3D%3D"
            target="blank"
          >
            <i className="fa fa-github">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  color: "#BF2792",
                }}
                className="icon"
              />{" "}
            </i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

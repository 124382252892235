export const dateFormat = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  // Create a new Date object
  const date = new Date(dateStr);

  // Define an options object for formatting
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the date using toLocaleDateString
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate;
};

export const getUpcomingMonths = (numMonths) => {
  if (numMonths < 4 || numMonths > 8) {
    throw new Error("Number of months must be between 4 and 8");
  }

  const currentDate = new Date();
  const months = [];
  const options = { month: "long" };

  for (let i = 0; i < numMonths; i++) {
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + i,
      1
    );
    const formattedMonth = futureDate.toLocaleDateString("en-GB", options);
    months.push(formattedMonth);
  }

  return months;
};

export const getLongMonth = (date) => {
  const currentDate = new Date(date);

  // Define the options for formatting the month name
  const options = { month: "long" };

  // Format the current date to get the month name
  const currentMonth = currentDate.toLocaleDateString("en-GB", options);

  return currentMonth;
};

export const getPackagesForCurrentAndUpcomingMonths = (packages, numMonths) => {
  const currentDate = new Date();
  const selectedPackages = [];
  const monthsIncluded = new Set();

  packages.forEach((pkg) => {
    if (!pkg.departureTime) return;

    const departureDate = new Date(pkg.departureTime);
    const yearMonth = `${departureDate.getFullYear()}-${departureDate.getMonth()}`;

    console.log({ yearMonth, selectedPackages });

    if (
      !monthsIncluded.has(yearMonth) &&
      (departureDate.getFullYear() > currentDate.getFullYear() ||
        (departureDate.getFullYear() === currentDate.getFullYear() &&
          departureDate.getMonth() >= currentDate.getMonth())) &&
      (departureDate.getFullYear() < currentDate.getFullYear() + 1 ||
        (departureDate.getFullYear() === currentDate.getFullYear() + 1 &&
          departureDate.getMonth() < currentDate.getMonth() + numMonths))
    ) {
      selectedPackages.push(pkg);
      monthsIncluded.add(yearMonth);
    }
  });

  return selectedPackages;
};

import React from "react";
import { NavLink } from "react-router-dom";
import data from "./data"; // Import your data here

const Index = () => {
  return (
    <div>
      <div className="heading">
        <h1>International Tours</h1>
        <p>
          Below you will find an overview of all Umrah and Hajj packages this
          month. Click on one of the trips for detailed information about this
          package.
        </p>
      </div>
      <div className="cards">
        {data.map((card, index) => (
          <div
            className="innercards"
            key={index}
            style={{ backgroundImage: `url(${card.image})` }}
          >
            <h2>{card.title}</h2>
            <div className="checkin">
              <NavLink to="/tour">
                <h3>View packages</h3>
              </NavLink>
            </div>
          </div>
        ))}
      </div>
      <div className="heading">
        <h1>Upcoming trips</h1>
        <p>
          Below you will find the most current trips that we are currently
          planning. Click on one of the trips for detailed information about
          this package.
        </p>
      </div>
    </div>
  );
};

export default Index;
